import * as React from "react"
import { graphql } from 'gatsby';
import parse from 'html-react-parser';

import Layout from 'components/layout';
import Link from 'components/link';
import Mission from 'components/mission';
import PostCard from 'components/postCard';
import ReadTime from 'components/readTime';
import Seo from 'components/seo';

import * as style from 'styles/post-detail.module.scss';

const PostPage = (props) => {
	let { allBlogPost: {edges: posts} } = props.data;
	posts = posts.map(item => item.node);
	if (posts.length === 0)return null;
	const post = posts[0];
	let topicName = '';
	if (!!post.Topic !== false && post.Topic.length)topicName = post.Topic[0].object.name;

	let { morePosts: {edges: morePosts} } = props.data;
	morePosts = morePosts.map(item => item.node);
	return (
		<Layout>
			<Seo title={post.title} description={post.intro} />
			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="small-12 cell">
						<section className={style.container}>
							<div className="grid-x">
								<div className="small-10 small-offset-1 medium-offset-1 medium-6 cell end">
									<h1 className={style.title}>{ post.title }</h1>
									<div className={style.topic}><ReadTime className={style.readTime} data={post.body} /><div className={style.topicName}>{topicName}</div></div>
								</div>
							</div>
							<div className="grid-x">
								<div className="small-10 small-offset-1 cell end">
									<div className={style.body}>{ parse(post.body) }</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>

			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="small-12 cell">
						<div className={style.more}>More Posts</div>
					</div>
				</div>
			</div>

			<div className="grid-container">
				<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
					{morePosts.map(post => <div className={`cell ${style.card}`}><PostCard {...post} /></div>)}
				</div>
			</div>

			<div className="grid-container">
				<div className="grid-x grid-margin-x">
					<div className="cell small-12">
						<div className={style.pager}>
							<Link to="/articles/" className="button secondary dark">See More Articles</Link>
						</div>
					</div>
				</div>
			</div>

			<Mission />
		</Layout>
	)
}

export default PostPage

export const query = graphql`
	query ($permalink:String) {
	  allBlogPost(filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        title
	        intro
	        body
	        Topic {
	          object {
	            name
	          }
	        }
	      }
	    }
	  }

		morePosts: allBlogPost(
		    filter: {permalink: {ne: $permalink}}
		    limit: 3
		    sort: {fields: showDate, order: DESC}
		  ) {
		    edges {
		      node {
		        id
		        permalink
		        title
		        intro
		        photo
		      }
		    }
		  }
  	}
`